import { ResultEnum } from "../../enum/ResultEnum";
import {
  GlobalController,
  GlobalControllerSingle,
} from "../../global/controller/GlobalController";
import { IBaseDTO } from "../../interface/model/base/IBaseDTO";
import { IBaseParams } from "../../interface/model/base/IBaseParams";
import { IMyAxios } from "../../interface/utils/IMyAxios";
import { MyAxios } from "../../utils/MyAxios";

export abstract class BaseApi {
  private globalController: GlobalController;
  public myAxios: IMyAxios;
  constructor() {
    this.myAxios = new MyAxios();
    this.globalController = GlobalControllerSingle.createInstance();
  }

  private openLoading() {
    this.globalController.loadingGlobal.openLoading();
  }

  private closeLoading() {
    this.globalController.loadingGlobal.closeLoading();
  }

  async get<T extends IBaseDTO>(url: string): Promise<T | undefined> {
    this.openLoading();
    const response = await this.myAxios.get<T>(url);
    this.closeLoading();
    const result = response.data;
    if (result.code === ResultEnum.Success) {
      if (!result.msg) {
        //全局弹窗
      }
      return result.data;
    }
    if (!result.code && !result.msg && !result.data) {
      return result as unknown as T;
    }
  }

  async post<T extends IBaseDTO>(
    url: string,
    data: any
  ): Promise<T | undefined> {
    this.openLoading();
    const response = await this.myAxios.post<T>(url, data);
    this.closeLoading();
    const result = response.data;
    if (result.code === ResultEnum.Success) {
      if (!result.msg) {
        //全局弹窗
      }
      return result.data;
    }
    if (!result.code && !result.msg && !result.data) {
      return result as unknown as T;
    }
  }
}
