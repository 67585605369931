import { Box, TextField } from "@mui/material";
import { makeAutoObservable } from "mobx";
import { Observer, observer, useLocalObservable } from "mobx-react-lite";
import { useTranslation } from "next-i18next";
import React, { FC } from "react";
import { IValidator } from "../../interface/component/IValidator";
import { IFormComponentVO } from "../../interface/model/component/IFormComponentVO";

export interface IMyTextFieldVO extends IFormComponentVO {
  label: string | React.ReactNode;
  value: string;
}

export class MyTextFieldVO implements IMyTextFieldVO, IValidator {
  key: string;
  label: string | React.ReactNode;
  value: string;
  required: boolean;
  isError: boolean;
  errorText: string | React.ReactNode;
  constructor(params?: {
    key?: string;
    value?: string;
    label?: string | React.ReactNode;
    placeholder?: string | React.ReactNode;
    required?: boolean;
    isError?: boolean;
    errorText?: string | React.ReactNode;
  }) {
    this.isError = false;
    this.errorText = <></>;
    this.key = params?.key ?? "";
    this.label = params?.label ?? "";
    this.value = params?.value ?? "";
    this.required = false;
    makeAutoObservable(this);
  }

  validator() {
    if (this.value) {
      this.isError = false;
    } else {
      this.isError = true;
    }
    return this.isError;
  }

  getValue() {
    return {
      [this.key]: this.value,
    };
  }

  changeValue(value: string) {
    this.value = value;
  }
}

export const MyTextField: FC<{ viewModel?: MyTextFieldVO,label?:string,errorText?:string }> = ({
  viewModel = new MyTextFieldVO(),
  label,
  errorText
}) => {
  const vo = useLocalObservable(() => viewModel);
  return (
    <Observer>
      {() => (
        <TextField
          label={label ?? vo.label}
          value={vo.value}
          // placeholder={vo.placeholder}
          onChange={(e) => {
            vo.changeValue(e.target.value);
          }}
          fullWidth
          required={vo.required}
          error={vo.isError}
          helperText={vo.isError ? (errorText ?? vo.errorText) : ""}
        ></TextField>
      )}
    </Observer>
  );
};

export const useMyTextField = (params: MyTextFieldVO = new MyTextFieldVO()) => {
  const vo = useLocalObservable(() => params);
  return {
    vo,
    Template: observer(() => {
      return (
        <TextField
          autoFocus
          label={vo.label}
          value={vo.value}
          helperText={vo.errorText}
          //placeholder={vo.placeholder}
          onChange={(e) => {
            vo.changeValue(e.target.value);
          }}
          error={vo.isError}
        ></TextField>
      );
    }),
  };
};
