import { useEffect, useState } from "react";
import {
  IReadonlyParam,
  IReadonlyProps,
} from "../../interface/component/IReadOnly";

export function useFormReadonly(params?: IReadonlyParam): IReadonlyProps {
  const [isReadonly, setIsReadonly] = useState<boolean>(
    params?.isReadonly ?? false
  );
  useEffect(() => {
    setIsReadonly(params?.isReadonly ?? false);
  }, [params?.isReadonly]);
  return {
    isReadonly,
    setIsReadonly,
  };
}
