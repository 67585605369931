import { makeAutoObservable } from "mobx";

export class LoadingGlobal{
  private isLoading: boolean;
  public get IsLoading() {
    return this.isLoading;
  }
  constructor() {
    this.isLoading = false;
    makeAutoObservable(this);
  }
  public openLoading() {
    this.isLoading = true;
  }
  public closeLoading() {
    this.isLoading = false;
  }
}

export class LoadingGlobalSingle {
  private static loadingGlobal: LoadingGlobal;
  private constructor() {}
  public static createInstance() {
    if (this.loadingGlobal === undefined) {
      this.loadingGlobal = new LoadingGlobal();
    }
    return this.loadingGlobal;
  }
}
