import { makeAutoObservable } from "mobx";
import { LoadingGlobal } from "../../model/global/LoadingGlobal";

export class GlobalController {
  loadingGlobal: LoadingGlobal;
  constructor() {
    this.loadingGlobal = new LoadingGlobal();
  }
}

export class GlobalControllerSingle {
  private static globalController: GlobalController;
  private constructor() {}
  public static createInstance() {
    if (this.globalController === undefined) {
      this.globalController = new GlobalController();
    }
    return this.globalController;
  }
}
