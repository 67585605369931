import { Grid, TextField } from "@mui/material";
import { makeAutoObservable } from "mobx";
import { Observer, useLocalObservable } from "mobx-react-lite";
import React, { FC, useEffect } from "react";
import { LoginApi } from "../../api/LoginApi";
import { IComponent } from "../../interface/component/IComponent";
import { ILoading } from "../../interface/component/ILoading";
import { IValidator } from "../../interface/component/IValidator";
import { IGetCaptchaParam } from "../../interface/model/param/IGetCaptchaParam";
import { IGetCaptchaVO } from "../../interface/model/vo/IGetCaptchaVO";
import { IMyAxios } from "../../interface/utils/IMyAxios";
import { MyAxios } from "../../utils/MyAxios";
import { MyTextField, MyTextFieldVO } from "../textfield/MyTextField";

export class MyValidatorCodeVO
  implements
    IGetCaptchaVO,
    IGetCaptchaParam,
    ILoading,
    IComponent<IGetCaptchaVO>,
    IValidator
{
  width: number;
  height: number;
  captchaId: string;
  captchaBase64Src: string;
  isLoading: boolean;
  loginApi: LoginApi;
  myTextFieldVO: MyTextFieldVO;
  isError: boolean;
  errorText: string;
  constructor(params?: {
    width?: number;
    height?: number;
    captchaId?: string;
    captchaBase64Src?: string;
    isLoading?: boolean;
  }) {
    this.isError = false;
    this.errorText = "请输入验证码";
    this.width = params?.width ?? 200;
    this.height = params?.height ?? 50;
    this.captchaId = params?.captchaId ?? "";
    this.captchaBase64Src = params?.captchaBase64Src ?? "";
    this.isLoading = true;
    this.myTextFieldVO = new MyTextFieldVO({
      label: "验证码",
      isError: this.isError,
      errorText: this.errorText,
    });
    makeAutoObservable(this);
    this.loginApi = new LoginApi();
    this.initialData();
  }

  validator(): boolean {
    return this.myTextFieldVO.validator();
  }
  getValue(): IGetCaptchaVO {
    return {
      captchaBase64Src: this.captchaBase64Src,
      captchaId: this.captchaId,
    };
  }
  setValue(value: IGetCaptchaVO): void {
    throw new Error("Method not implemented.");
  }
  async initialData(): Promise<void> {
    const data = await this.loginApi.getCaptcha({
      height: this.height,
      width: this.width,
    });
    if (data) {
      this.captchaBase64Src = data.captchaBase64Src;
      this.captchaId = data.captchaId;
    }
  }
  clearData(): void {
    throw new Error("Method not implemented.");
  }
  toggleLoading() {
    this.isLoading = !this.isLoading;
  }
}

export const MyValidatorCode: FC<{ viewmodel: MyValidatorCodeVO }> = ({
  viewmodel,
}) => {
  const vo = useLocalObservable(() => viewmodel);
  return (
    <Observer>
      {() => (
        <Grid item container columnSpacing={2}>
          <Grid md={8} xs={8} item>
            <MyTextField viewModel={vo.myTextFieldVO} />
          </Grid>
          <Grid md={4} xs={4} item>
            {vo.captchaBase64Src && (
              <img src={vo.captchaBase64Src} width="100%" height={"100%"} />
            )}
          </Grid>
        </Grid>
      )}
    </Observer>
  );
};
