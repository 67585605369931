import { GetServerSideProps, NextPage } from "next";
import { useLoginModule } from "../module/loginmodule/loginmodule";
import { LoginModule } from "../module/loginmodule/loginmoduleFC";
import {
  getServerSidePropsDefalutSetting,
  getServerSidePropsLoginSetting,
} from "../ssr/SSR";

interface ILoginProps {
  csrfToken: string;
}

const Login: NextPage<ILoginProps> = ({ csrfToken }) => {
  const loginModule = useLoginModule({ csrfToken });
  return <LoginModule csrfToken={csrfToken} />;
};

export const getServerSideProps: GetServerSideProps =
  getServerSidePropsLoginSetting;

export default Login;
