import { ThemeProvider } from "@emotion/react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useLocale } from "../../hooks/useLocale";
import { themeOptions } from "../../theme/theme";
import LogoImg from "../../public/assets/picture/logo.png";
import Image from "next/image";
import { MyLocale } from "../../component/locale/MyLocale";
import {
  MyTextField,
  useMyTextField,
} from "../../component/textfield/MyTextFieldFC";
import {
  MyValidatorCode,
  useMyValidatorCode,
} from "../../component/validatorcode/MyValidatorCodeFC";
import { useTranslation } from "next-i18next";
import { IValidator } from "../../interface/component/IValidator";
import { useCallback } from "react";
import { signIn } from "next-auth/react";
import { NextAuthCredentialsEnum } from "../../enum/NextAuthCredentialsEnum";
import { IUserLoginParam } from "../../interface/model/param/IUserLoginParam";
import { MyMessage } from "../../utils/MyMessage";
import { ConfirmTypeEnum, useMyConfirm } from "../../context/ConfirmContext";
import { useRouter } from "next/router";
import { RouterPath } from "../../enum/RouterPath";
export const styled = (
  <style jsx global>{`
    body {
      background: url("/assets/picture/bg.jpg");
    }
  `}</style>
);

const useLoginModule = () => {
  const router = useRouter();
  const { t, language } = useLocale();
  const confirm = useMyConfirm();
  const username = useMyTextField({
    label: t("username"),
    errorText: `${t("username")}${t("isnotempty")}`,
  });
  const password = useMyTextField({
    label: t("password"),
    errorText: `${t("password")}${t("isnotempty")}`,
  });
  const validatorCode = useMyValidatorCode({
    label: t("verificationcode"),
    errorText: `${t("verificationcode")}${t("isnotempty")}`,
  });

  const validator = useCallback(() => {
    return true;
  }, [username.value, password.value, validatorCode.myTextField.value]);
  const login = useCallback(async () => {
    if (validator()) {
      const message: IUserLoginParam & { language: string } = {
        username: username.value,
        password: password.value,
        captcha: validatorCode.myTextField.value,
        captchaId: validatorCode.captchaId,
        language,
      };
      const result: any = await signIn(
        NextAuthCredentialsEnum.NormalLoginCredentials,
        {
          message: JSON.stringify(message),
          redirect: false,
        }
      );
      console.log(result);
      if (result.error)
        return confirm?.show({
          content: result.error,
          type: ConfirmTypeEnum.Error,
        });
      else {
        router.push(RouterPath.Goods);
      }
    }
  }, [username.value, password.value, validatorCode.myTextField.value]);
  return {
    login,
    username,
    password,
    validatorCode,
  };
};

interface ILoginModuleProps {
  csrfToken: string;
}
export function LoginModule(props: ILoginModuleProps) {
  const { username, password, validatorCode, login } = useLoginModule();
  return (
    <ThemeProvider theme={themeOptions}>
      {styled}
      <Stack alignItems={"center"}>
        <Box sx={{ mt: 8 }}>
          <Image src={LogoImg} width={172} height={56} />
        </Box>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Paper>
            <Box
              sx={{
                padding: 4,
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                <MyLocale value="signin" />
              </Typography>
              <Box component="form" noValidate sx={{ mt: 2 }}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item md={12} xs={12}>
                    <MyTextField {...username} />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <MyTextField {...password} />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <MyValidatorCode {...validatorCode} />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={login}
                    >
                      <MyLocale value="signin" />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Container>
      </Stack>
    </ThemeProvider>
  );
}
