import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import { LoginApi } from "../../api/LoginApi";
import { IComponent } from "../../interface/component/IComponent";
import { IInitialData } from "../../interface/component/IInitialData";
import { ILoading } from "../../interface/component/ILoading";
import { IValidator } from "../../interface/component/IValidator";
import { IGetCaptchaParam } from "../../interface/model/param/IGetCaptchaParam";
import { IGetCaptchaVO } from "../../interface/model/vo/IGetCaptchaVO";
import { MyPartial } from "../../type/MyPartial";
import { MyAxios } from "../../utils/MyAxios";
import {
  IMyTextFieldProps,
  MyTextField,
  useMyTextField,
} from "../textfield/MyTextFieldFC";

interface IMyValidatorCode
  extends IGetCaptchaVO,
    IGetCaptchaParam,
    IInitialData,
    IValidator {
  reloadValidatorCode(): Promise<void>;
}

//@ts-ignore
export const useMyValidatorCode: (
  params: MyPartial<IMyValidatorCode> & MyPartial<IMyTextFieldProps>
) => IMyValidatorCode & { myTextField: IMyTextFieldProps } = (params) => {
  const myTextField = useMyTextField(params);
  const [captchaId, setCaptchaId] = useState<string>(params.captchaId ?? "");
  const [captchaBase64Src, setCaptchaBase64Src] = useState<string>(
    params.captchaBase64Src ?? ""
  );
  const [isError, setIsError] = useState<boolean>(params.isError ?? false);
  const initialData = useCallback(async () => {
    const loginApi = new LoginApi();
    const data = await loginApi.getCaptcha({
      height: params.height ?? 50,
      width: params.width ?? 200,
    });
    if (data) {
      setCaptchaId(data.captchaId);
      setCaptchaBase64Src(data.captchaBase64Src);
    }
  }, []);
  const validator = useCallback(() => {
    if (myTextField.value == "") {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [myTextField.value]);
  const reloadValidatorCode = async () => {
    initialData();
  };
  useEffect(() => {
    initialData();
  }, []);
  return {
    initialData,
    validator,
    reloadValidatorCode,
    width: params.width ?? 200,
    height: params.width ?? 50,
    captchaId,
    myTextField,
    captchaBase64Src,
    isError,
    errorText: params.errorText ?? "",
  };
};
const MyImg = styled("img")({
  height: "100%",
  width: "100%",
  cursor: "pointer",
});

export const MyValidatorCode: FC<
  IMyValidatorCode & { myTextField: IMyTextFieldProps }
> = ({ myTextField, captchaBase64Src, reloadValidatorCode }) => {
  return (
    <Grid item container columnSpacing={2}>
      <Grid md={8} xs={8} item>
        <MyTextField {...myTextField} />
      </Grid>
      <Grid md={4} xs={4} item>
        {captchaBase64Src && (
          <MyImg onClick={reloadValidatorCode} src={captchaBase64Src} />
        )}
      </Grid>
    </Grid>
  );
};
