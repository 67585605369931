import { InputAdornment, TextField } from "@mui/material";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useFormReadonly } from "../../hooks/form/useFormReadonly";
import {
  useFormValidator,
  ValidatorTypeEnum,
} from "../../hooks/form/useFormValidator";
import { IReadonlyProps } from "../../interface/component/IReadOnly";
import { IValidator } from "../../interface/component/IValidator";
import { MyPartial } from "../../type/MyPartial";

export interface IMyTextFieldProps<T extends any = any>
  extends IValidator,
    IReadonlyProps {
  label: string;
  value: any;
  required: boolean;
  isError: boolean;
  errorText: string;
  onChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void;
  onBlur(): void;
  key: any;
  setValue: (value: React.SetStateAction<string>) => void;
}

export interface IMyTextFieldParam<T = any> extends IMyTextFieldProps<T> {
  key: keyof T;
  data?: { [key: string]: any };
  validatorType?: ValidatorTypeEnum;
  validatorMethod?: (value: any) => boolean;
  onWatch?: (params: {
    oldValue: any;
    newValue: any;
    setValue: (value: React.SetStateAction<string>) => void;
  }) => void;
}

export function useMyTextField<T>(
  params: MyPartial<IMyTextFieldParam<T>>
): IMyTextFieldProps<any> {
  const [label, setLabel] = useState<string>(params.label ?? "");
  const [value, sv] = useState<string>(
    (params.value as unknown as string) ?? ""
  );
  const [required, setRequired] = useState<boolean>(params.required ?? false);
  const { validator, errorText, isError } = useFormValidator({
    value,
    errorText: params.errorText,
    validatorType: params.validatorType,
    validatorMethod: params.validatorMethod,
  });
  const { isReadonly } = useFormReadonly({ isReadonly: params.isReadonly });
  const onBlur = useCallback(() => {}, [value]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (params.onWatch) {
        params.onWatch({
          oldValue: value,
          newValue: event.target.value,
          setValue: sv,
        });
      } else {
        sv(event.target.value);
      }
    },
    [value]
  );
  useEffect(() => {
    sv(params?.data ? params.data[params.key as any] : params.value ?? "");
  }, [JSON.stringify(params.data)]);

  useEffect(() => {
    if (params.value) {
      sv(params.value as unknown as string);
    }
  }, [params.value]);

  return {
    label,
    value,
    required,
    isError,
    errorText,
    onChange,
    onBlur,
    key: params.key ?? "",
    validator,
    isReadonly,
    setValue: sv,
  };
}

export const MyTextField: FC<IMyTextFieldProps<any>> = ({
  label,
  value,
  onChange,
  required,
  errorText,
  isError,
  onBlur,
  isReadonly,
}) => {
  return (
    <TextField
      label={label}
      value={value}
      disabled={isReadonly}
      onBlur={onBlur}
      onChange={onChange}
      fullWidth
      required={required}
      error={isError}
      helperText={isError ? errorText : ""}
    ></TextField>
  );
};

export const MyTextFieldNumberMinute: FC<IMyTextFieldProps<any>> = ({
  label,
  value,
  onChange,
  required,
  errorText,
  isError,
  isReadonly,
}) => {
  return (
    <TextField
      label={label}
      value={value}
      type={"number"}
      disabled={isReadonly}
      InputProps={{
        endAdornment: <InputAdornment position={"end"}>分钟</InputAdornment>,
      }}
      onChange={onChange}
      fullWidth
      required={required}
      error={isError}
      helperText={isError ? errorText : ""}
    ></TextField>
  );
};

export const MyTextFieldNumberUSD: FC<IMyTextFieldProps<any>> = ({
  label,
  value,
  onChange,
  required,
  errorText,
  isError,
  isReadonly,
}) => {
  return (
    <TextField
      label={label}
      value={value}
      type={"number"}
      disabled={isReadonly}
      InputProps={{
        endAdornment: <InputAdornment position={"end"}>USD</InputAdornment>,
      }}
      onChange={onChange}
      fullWidth
      required={required}
      error={isError}
      helperText={isError ? errorText : ""}
    ></TextField>
  );
};

export const MyTextFieldNumberPercentage: FC<IMyTextFieldProps<any>> = ({
  label,
  value,
  onChange,
  required,
  errorText,
  isError,
  isReadonly,
}) => {
  return (
    <TextField
      label={label}
      value={value}
      type={"number"}
      disabled={isReadonly}
      InputProps={{
        endAdornment: <InputAdornment position={"end"}>%</InputAdornment>,
      }}
      onChange={onChange}
      fullWidth
      required={required}
      error={isError}
      helperText={isError ? errorText : ""}
    ></TextField>
  );
};

export const MyTextFieldNumber: FC<IMyTextFieldProps<any>> = ({
  label,
  value,
  onChange,
  required,
  errorText,
  isError,
  isReadonly,
}) => {
  return (
    <TextField
      label={label}
      value={value}
      type={"number"}
      disabled={isReadonly}
      onChange={onChange}
      fullWidth
      required={required}
      error={isError}
      helperText={isError ? errorText : ""}
    ></TextField>
  );
};

export const MyTextFieldStandard: FC<IMyTextFieldProps<any>> = ({
  label,
  value,
  onChange,
  required,
  errorText,
  isError,
  isReadonly,
}) => {
  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      disabled={isReadonly}
      fullWidth
      required={required}
      error={isError}
      helperText={isError ? errorText : ""}
      variant="standard"
    ></TextField>
  );
};

export const MyTextFieldStandardNumber: FC<IMyTextFieldProps<any>> = ({
  label,
  value,
  onChange,
  required,
  errorText,
  isError,
  isReadonly,
}) => {
  return (
    <TextField
      label={label}
      value={value}
      type={"number"}
      disabled={isReadonly}
      onChange={onChange}
      fullWidth
      required={required}
      error={isError}
      helperText={isError ? errorText : ""}
      variant="standard"
    ></TextField>
  );
};

export const MyTextFieldMulti: FC<IMyTextFieldProps<any>> = ({
  label,
  value,
  onChange,
  required,
  errorText,
  isError,
  isReadonly,
}) => {
  return (
    <TextField
      multiline
      label={label}
      value={value}
      disabled={isReadonly}
      onChange={onChange}
      fullWidth
      // maxRows={4}
      required={required}
      error={isError}
      helperText={isError ? errorText : ""}
    ></TextField>
  );
};

export const MyTextFieldReadonly: FC<IMyTextFieldProps<any>> = ({
  label,
  value,
  onChange,
  required,
  errorText,
  isError,
  isReadonly,
}) => {
  return <div>{`${label}${value}`}</div>;
};
