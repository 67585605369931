import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AppBar, Paper, Stack } from "@mui/material";
import { themeOptions } from "../../theme/theme";
import LogoImg from "../../public/assets/picture/logo.png";
import Image from "next/image";
import { signIn } from "next-auth/react";
import { NextAuthCredentialsEnum } from "../../enum/NextAuthCredentialsEnum";
import { MyLocale } from "../../component/locale/MyLocale";
import {
  MyValidatorCode,
  MyValidatorCodeVO,
} from "../../component/validatorcode/MyValidatorCode";
import { useLocalObservable } from "mobx-react-lite";
import {
  MyTextField,
  MyTextFieldVO,
} from "../../component/textfield/MyTextField";
import { useTranslation } from "next-i18next";
import { useLocale } from "../../hooks/useLocale";
export const styled = (
  <style jsx global>{`
    body {
      background: url("/assets/picture/bg.jpg");
    }
  `}</style>
);

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

interface ILoginModuleProps {
  csrfToken: string;
}

export class LoginModuleVO {
  t: any;
  usernameTextFieldVO: MyTextFieldVO;
  passwordTextFieldVO: MyTextFieldVO;
  myValidatorCodeVO: MyValidatorCodeVO;
  constructor(t: any) {
    this.t = t;
    this.usernameTextFieldVO = new MyTextFieldVO({
      key: "username",
    });
    this.passwordTextFieldVO = new MyTextFieldVO({
      key: "password",
    });
    this.myValidatorCodeVO = new MyValidatorCodeVO();
  }

  async signIn() {
    const formDataArray = [
      this.usernameTextFieldVO,
      this.passwordTextFieldVO,
      this.myValidatorCodeVO,
    ];
    if (formDataArray.every((item) => item.validator())) {
    }
    const result = await signIn(
      NextAuthCredentialsEnum.NormalLoginCredentials,
      {
        message: {
          ...this.usernameTextFieldVO.getValue(),
          ...this.passwordTextFieldVO.getValue(),
          ...this.myValidatorCodeVO.getValue(),
        },
      }
    );
    if (result) {
    } else {
      alert("登录失败");
    }
  }
}

export class LoginModuleVOSingle {
  private static _loginModuleVO: LoginModuleVO;
  private constructor() {}
  public static createInstance(t: any) {
    if (this._loginModuleVO == undefined) {
      this._loginModuleVO = new LoginModuleVO(t);
    }
    return this._loginModuleVO;
  }
}

export function useLoginModule(props: ILoginModuleProps) {
  const { t } = useLocale();
  const vo = useLocalObservable(() => LoginModuleVOSingle.createInstance(t));
  return {
    Template: () => {
      return (
        <ThemeProvider theme={themeOptions}>
          {styled}
          <Stack alignItems={"center"}>
            <Box sx={{ mt: 8 }}>
              <Image src={LogoImg} width={172} height={56} />
            </Box>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Paper>
                <Box
                  sx={{
                    padding: 4,
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography component="h1" variant="h5">
                    <MyLocale value="signin" />
                  </Typography>
                  <Box
                    component="form"
                    onSubmit={(e: any) => vo.signIn()}
                    noValidate
                    sx={{ mt: 2 }}
                  >
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      <Grid item md={12} xs={12}>
                        <MyTextField
                          label={t("username")}
                          errorText={`${t("username")}${t("isnotempty")}`}
                          viewModel={vo.usernameTextFieldVO}
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <MyTextField
                          label={t("password")}
                          errorText={`${t("password")}${t("isnotempty")}`}
                        viewModel={vo.passwordTextFieldVO} />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <MyValidatorCode viewmodel={vo.myValidatorCodeVO} />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Button type="submit" fullWidth variant="contained">
                          <MyLocale value="signin" />
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid container>
                      {/* <Grid item xs>
                        <Link href="#" variant="body2">
                          Forgot password?
                        </Link>
                      </Grid> */}
                      {/* <Grid item>
                        <Link href="#" variant="body2">
                          {"Don't have an account? Sign Up"}
                        </Link>
                      </Grid> */}
                    </Grid>
                  </Box>
                </Box>
              </Paper>
              <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
          </Stack>
        </ThemeProvider>
      );
    },
  };
}
