import axios, { Axios } from "axios";
import { IBaseDTO } from "../interface/model/base/IBaseDTO";
import { IResult } from "../interface/model/base/IResultT";
import { IMyAxios } from "../interface/utils/IMyAxios";
import { getSession } from "next-auth/react";
import { GlobalContext } from "../global/context/GlobalContext";
import { IMySession } from "../interface/model/auth/IMyAuth";
import { IBaseVO } from "../interface/model/base/IBaseVO";
import { RequestUrl } from "../config/config";

export class MyAxios implements IMyAxios {
  axios: Axios;
  constructor() {
    this.axios = new axios.Axios({
      transformRequest: axios.defaults.transformRequest,
      baseURL: RequestUrl,
    });
    this.axios.interceptors.request.use(async (config) => {
      const session = await getSession(GlobalContext.ctx);
      if (session) {
        // config.headers!["X-Exchange-Info"] = (
        //   session as IMySession
        // ).accessToken;
      }
      config.headers![
        "X-Exchange-Info"
      ] = `version=1.0&source=web&appname=kooolacreate&language=en`;
      return config;
    });
  }
  async get<T extends IBaseDTO>(url: string) {
    return this.axios.get<IResult<T>>(url, {
      transformResponse: (data) => JSON.parse(data),
    });
  }

  async post<T extends IBaseDTO>(url: string, data: IBaseVO) {
    return this.axios.post<IResult<T>>(url, data, {
      transformResponse: (data) => JSON.parse(data),
    });
  }
}
