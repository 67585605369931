import { useCallback, useEffect, useMemo, useState } from "react";

export enum ValidatorTypeEnum {
  IsNotEmpty,
  None,
  Custom,
}

export function useFormValidator(params: {
  value: any;
  errorText?: string;
  validatorType?: ValidatorTypeEnum;
  validatorMethod?: (value: any) => boolean;
}) {
  const [isError, setIsError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>(params.errorText ?? "");
  const validator = () => {
    let isError = false;
    let errorText = params?.errorText ?? "";
    switch (params.validatorType ?? ValidatorTypeEnum.None) {
      case ValidatorTypeEnum.IsNotEmpty:
        if (!params.value) {
          isError = true;
          errorText = errorText || "*此项必填";
        }
        break;
      case ValidatorTypeEnum.Custom:
        if (params.validatorMethod) {
          if (!params.validatorMethod(params.value)) {
            isError = true;
            errorText = errorText;
          }
        }
        break;
      case ValidatorTypeEnum.None:
      default:
        break;
    }
    setIsError(isError);
    setErrorText(errorText);
    return !isError;
  };

  useEffect(() => {
    if (isError) {
      validator();
    }
  }, [JSON.stringify(params.value)]);

  return {
    validator,
    isError,
    errorText,
  };
}
