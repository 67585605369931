import { BaseApi } from "../abstract/api/BaseApi";
import { IGetCaptchaParam } from "../interface/model/param/IGetCaptchaParam";
import { IUserLoginParam } from "../interface/model/param/IUserLoginParam";
import { IGetCaptchaVO } from "../interface/model/vo/IGetCaptchaVO";
import { IUserLoginVO } from "../interface/model/vo/IUserLoginVO";

export enum LoginApiEnum {
  /**
   * @description 获取验证码
   */
  getCaptcha = "/getCaptcha",

  /**
   * @description 登录
   */
  login = "/login",
}

export class LoginApi extends BaseApi {
  async getCaptcha(params: IGetCaptchaParam) {
    return await this.post<IGetCaptchaVO>(LoginApiEnum.getCaptcha, params);
  }

  async login(params: IUserLoginParam) {
    return await this.myAxios.post<IUserLoginVO>(LoginApiEnum.login, params);
  }
}
